:root {
    --tm-border-color: #333;
    --tm-bg-color: #f8f9fa;
    --tm-text-color: #212529;
    --tm-header-border: #333;
    --gauge-bg: #f8f9fa;
    --gauge-panel-bg: #e9ecef;
    --gauge-border: #495057;
    --gauge-text: #212529;
    --gauge-highlight: rgba(255, 255, 255, 0.8);
    --gauge-shadow: rgba(0, 0, 0, 0.1);
    --control-bg: #f8f9fa;
    --control-border: #dee2e6;
    --control-text: #212529;
    --control-hover: #e9ecef;
    --control-active: #0d6efd;
    --control-active-text: #fff;
    --control-disabled: #6c757d;
    --toggle-bg: #e9ecef;
    --toggle-border: #495057;
    --toggle-active: #0d6efd;
    --toggle-handle: #f8f9fa;
    --toggle-text: #212529;
    --toggle-disabled: #6c757d;
}

@media (prefers-color-scheme: dark) {
    :root {
        --gauge-bg: #212529;
        --gauge-panel-bg: #343a40;
        --gauge-border: #6c757d;
        --gauge-text: #f8f9fa;
        --gauge-highlight: rgba(255, 255, 255, 0.1);
        --gauge-shadow: rgba(0, 0, 0, 0.2);
        --control-bg: #343a40;
        --control-border: #495057;
        --control-text: #f8f9fa;
        --control-hover: #495057;
        --control-active: #3b89ff;
        --control-active-text: #fff;
        --control-disabled: #6c757d;
        --toggle-bg: #343a40;
        --toggle-border: #6c757d;
        --toggle-active: #3b89ff;
        --toggle-handle: #f8f9fa;
        --toggle-text: #f8f9fa;
        --toggle-disabled: #495057;
        --tm-border-color: #6c757d;
        --tm-bg-color: #212529;
        --tm-text-color: #e9ecef;
        --tm-header-border: #6c757d;
    }
}

.gauge-panel {
    position: relative;
    border: 2px solid var(--gauge-border);
    border-radius: 4px;
    padding: 1.5rem 1rem;
    height: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    background-color: var(--gauge-panel-bg);
    box-shadow: 0 2px 4px var(--gauge-shadow);
    transition: all 0.3s ease;
}

.gauge-panel::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    height: 1px;
    background: var(--gauge-highlight);
}

.gauge-header {
    text-transform: uppercase;
    font-family: "JetBrains Mono", Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    color: var(--gauge-text);
    text-align: center;
    border-bottom: 1px solid var(--gauge-border);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
}

.gauge-value {
    font-family: "JetBrains Mono", Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin: auto;
    line-height: 1.4;
    color: var(--gauge-text);
}

.gauge-panel:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px var(--gauge-shadow);
}

.tech-manual-section {
    border: 3px solid var(--tm-border-color);
    border-radius: 0;
    background-color: var(--tm-bg-color);
    padding: 1.25rem;
    margin: 1rem 0;
    position: relative;
    /* Add a subtle transition for color changes */
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.tech-manual-section::before,
.tech-manual-section::after {
    content: '';
    position: absolute;
    left: 2rem;
    right: 2rem;
    height: 3px;
    background-color: var(--tm-border-color);
    transition: background-color 0.3s ease;
}

.tech-manual-section::before {
    top: -3px;
}

.tech-manual-section::after {
    bottom: -3px;
}

.tech-manual-header {
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 2px solid var(--tm-header-border);
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    font-family: 'Univers LT Std', sans-serif;
    color: var(--tm-text-color);
}

.tech-manual-content {
    /*overflow-x: auto;*/
    /* Add styling for the scrollbar */
    /*scrollbar-width: thin;*/
    /*scrollbar-color: var(--tm-border-color) transparent;*/
}

/* Webkit scrollbar styling */
/*.tech-manual-content::-webkit-scrollbar {*/
/*    height: 8px;*/
/*}*/

/*.tech-manual-content::-webkit-scrollbar-track {*/
/*    background: transparent;*/
/*}*/

/*.tech-manual-content::-webkit-scrollbar-thumb {*/
/*    background-color: var(--tm-border-color);*/
/*    border-radius: 4px;*/
/*}*/

.tech-manual-content pre {
    font-family: "JetBrains Mono", Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 1rem;
    line-height: 1.6;
    color: var(--tm-text-color);
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    white-space: pre;
    word-wrap: normal;
}

.program-controls {
    padding: 1rem;
    background-color: var(--control-bg);
    border: 1px solid var(--control-border);
    border-radius: 4px;
    transition: all 0.3s ease;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.control-btn {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: var(--control-bg);
    border: 1px solid var(--control-border);
    color: var(--control-text);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: 'Univers LT Std', sans-serif;
    min-width: 120px;
    position: relative;
}

.control-btn:hover {
    background-color: var(--control-hover);
}

.control-btn:active {
    transform: translateY(1px);
}


.tech-control {
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    background-color: var(--tm-bg-color);
    border: 1px solid var(--tm-border-color);
    color: var(--tm-text-color);
    padding: 0.25rem 0.75rem;
    text-transform: uppercase;
    transition: all 0.2s ease;
    white-space: nowrap;
    overflow: auto;
}

.tech-control:hover {
    background-color: var(--tm-border-color);
    color: var(--tm-bg-color);
}

.tech-control:active {
    transform: translateY(1px);
}

.control-btn.active {
    background-color: var(--control-active);
    color: var(--control-active-text);
    border-color: var(--control-active);
}

.like-btn.liked {
    color: red;
}

.like-btn::before {
    content: '♡';
    margin-right: 0.5rem;
    transition: transform 0.2s ease;
}

.like-btn.liked::before {
    content: '♥';
    transform: scale(1.2);
    color: red;
}

/* Sync indicator */
.sync-indicator {
    position: absolute;
    right: 0.5rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 1rem;
    height: 1rem;
    border: 2px solid var(--control-active);
    border-top-color: transparent;
    border-radius: 50%;
}

.htmx-request .sync-indicator {
    opacity: 1;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Success feedback animation */
.control-btn.success::after {
    content: '✓';
    position: absolute;
    right: 0.5rem;
    opacity: 0;
    animation: fadeInOut 1.5s ease forwards;
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .workout-report {
        padding: 1rem 0.5rem;
    }

    .gauge-panel {
        margin-bottom: 1rem;
    }

    .gauge-value {
        font-size: 1.25rem;
    }

    .tech-control {
        padding-right: 0.5rem;
    }

    .tech-manual-section {
        padding: 1rem;
        margin: 1rem 0;
    }

    .tech-manual-content pre {
        font-size: 0.875rem;
    }

    .program-controls {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .control-btn {
        width: 100%;
        margin: 0;
        font-size: 0.875rem;
        min-height: 44px; /* Better touch target size */
    }
}

/* High contrast mode support */
@media (forced-colors: active) {
    .gauge-panel {
        border: 2px solid CanvasText;
    }

    .gauge-header,
    .gauge-value {
        color: CanvasText;
    }

    input:checked + .toggle-housing {
        background-color: Highlight;
    }

    .control-btn {
        border: 2px solid CanvasText;
    }

    .control-btn.active {
        background-color: Highlight;
        color: HighlightText;
    }
}

.tech-manual-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 3px solid var(--tm-border-color);
    background-color: var(--tm-bg-color);
    font-family: "JetBrains Mono", Menlo, Monaco, Consolas, "Courier New", monospace;
}

.tech-manual-list-item {
    position: relative;
    padding: 1rem 1rem 1rem 4rem;
    border-bottom: 1px solid var(--tm-border-color);
    transition: background-color 0.2s ease;
}

.tech-manual-list-item:last-child {
    border-bottom: none;
}


.tech-manual-list-item:hover {
    background-color: color-mix(in srgb, var(--tm-border-color) 5%, transparent);
}

.tech-manual-list-link {
    color: var(--tm-text-color);
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.tech-manual-list-link:hover {
    color: var(--control-active);
}

.tech-manual-list-date {
    font-weight: bold;
}

.tech-manual-list-time {
    opacity: 0.8;
    margin-left: 1rem;
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .tech-manual-list-item {
        padding: 0.75rem 0.75rem 0.75rem 3rem;
    }

    .tech-manual-list-item::before {
        left: 0.75rem;
    }

    .tech-manual-list-link {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
    }

    .tech-manual-list-time {
        margin-left: 0;
        font-size: 0.875rem;
    }
}
